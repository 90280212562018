import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { green, red } from '@material-ui/core/colors';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import AuthenticatedPage from '../components/authenticated-page';
import { decodeJWTPayload, getToken, setToken, navigate } from '../utils/utils';
import { GET_VASP_COUNT, GET_TRANSFER_STATISTICS } from '../crud';

function Profile() {
  const token = getToken('token');

  const logoutCallback = () => {
    setToken(undefined);
    navigate('/');
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    greenCell: {
      backgroundColor: green[200],
    },
    redCell: {
      backgroundColor: red[200],
    },
  });

  const classes = useStyles();

  // parse token to show user data, is it better to use JWT library?
  const user = decodeJWTPayload(token);

  const [vaspCount, setVaspCount] = useState('');
  const [getRows, setRows] = useState([]);
  useQuery(GET_TRANSFER_STATISTICS, {
    variables: {
      country_code: ['US', 'JP', 'KR', 'TW'],
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      setRows(data.getTransferStatistics);
    },
    fetchPolicy: 'cache-first',
  });

  useQuery(GET_VASP_COUNT, {
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: setVaspCount,
    fetchPolicy: 'cache-first',
  });

  const getWeekClassName = () => {
    if (getRows.totalCount === undefined) {
      return '';
    }
    if (getRows.totalCount.week - getRows.totalCount.oldWeek >= 0) {
      return classes.greenCell;
    }
    return classes.redCell;
  };

  const getMonthClassName = () => {
    if (getRows.totalCount === undefined) {
      return '';
    }
    if (getRows.totalCount.month - getRows.totalCount.oldMonth >= 0) {
      return classes.greenCell;
    }
    return classes.redCell;
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <Navigator breadcrumbTexts={['Home', 'Welcome page']} breadcrumbLinks={['/', '/profile']} />
        <h3>{`Hello ${user.name}`}</h3>
        <h4>{`Total VASP Number: ${vaspCount.vaspCount}`}</h4>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell component="th" scope="col" align="center">
                Week
              </TableCell>
              <TableCell component="th" scope="col" align="center">
                Growth
              </TableCell>
              <TableCell component="th" scope="col" align="center">
                Month
              </TableCell>
              <TableCell component="th" scope="col" align="center">
                Growth
              </TableCell>
              <TableCell component="th" scope="col" align="center">
                All Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows.countryStatistics === undefined
              ? []
              : getRows.countryStatistics.map((row) => (
                  <TableRow key={row.country}>
                    <TableCell component="th" scope="row" align="center">
                      {row.country}
                    </TableCell>
                    <TableCell align="center">{row.week}</TableCell>
                    <TableCell
                      align="center"
                      className={row.week - row.oldWeek >= 0 ? classes.greenCell : classes.redCell}
                    >
                      {((row.week - row.oldWeek) / (row.oldWeek <= 0 ? 1 : row.oldWeek)) * 100}%
                    </TableCell>
                    <TableCell align="center">{row.month}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        row.month - row.oldMonth >= 0 ? classes.greenCell : classes.redCell
                      }
                    >
                      {((row.month - row.oldMonth) / (row.oldMonth <= 0 ? 1 : row.oldMonth)) * 100}%
                    </TableCell>
                    <TableCell align="center">{row.allTime}</TableCell>
                  </TableRow>
                ))}
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                Total
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {getRows.totalCount === undefined ? '' : getRows.totalCount.week}
              </TableCell>
              <TableCell align="center" className={getWeekClassName()}>
                {getRows.totalCount === undefined
                  ? ''
                  : ((getRows.totalCount.week - getRows.totalCount.oldWeek) /
                      (getRows.totalCount.oldWeek <= 0 ? 1 : getRows.totalCount.oldWeek)) *
                    100}
                %
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {getRows.totalCount === undefined ? '' : getRows.totalCount.month}
              </TableCell>
              <TableCell align="center" className={getMonthClassName()}>
                {getRows.totalCount === undefined
                  ? ''
                  : ((getRows.totalCount.month - getRows.totalCount.oldMonth) /
                      (getRows.totalCount.oldMonth <= 0 ? 1 : getRows.totalCount.oldMonth)) *
                    100}
                %
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {getRows.totalCount === undefined ? '' : getRows.totalCount.total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button onClick={logoutCallback} variant="outlined">
          Log Out
        </Button>
      </AuthenticatedPage>
    </Layout>
  );
}

export default Profile;
